exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-sanity-blogs-slug-current-js": () => import("./../../../src/pages/{SanityBlogs.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-blogs-slug-current-js" */),
  "component---src-pages-sanity-industry-pages-slug-current-js": () => import("./../../../src/pages/{SanityIndustryPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-industry-pages-slug-current-js" */),
  "component---src-pages-sanity-locations-pages-slug-current-js": () => import("./../../../src/pages/{SanityLocationsPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-locations-pages-slug-current-js" */),
  "component---src-pages-sanity-pages-slug-current-js": () => import("./../../../src/pages/{SanityPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-pages-slug-current-js" */),
  "component---src-pages-sanity-profile-section-slug-current-js": () => import("./../../../src/pages/{SanityProfileSection.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-profile-section-slug-current-js" */),
  "component---src-pages-sanity-services-pages-slug-current-js": () => import("./../../../src/pages/{SanityServicesPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-services-pages-slug-current-js" */)
}

